import classNames from "classnames";
import React, { useState, useRef, useEffect } from "react";
import Circle4 from '../../resource/imgs/home/img_qiuti4.png'
import styles from './wedo.module.css'
import GameIcon from "../../resource/imgs/wedo/game.png";
import EcommerceIcon from "../../resource/imgs/wedo/ecommerce.png";
import Logo from '../../resource/imgs/logo.png'

const Wedo = ({ t }) => {
  const [selectIndex, setSelectIndex] = useState(0)
  const currentIndex = useRef()
  const timer = useRef()

  const autoPlay = () => {
    clearInterval(timer.current)
    timer.current = setInterval(() => {
      if (currentIndex.current === 1) {
        setSelectIndex(0)
      } else {
        setSelectIndex(currentIndex.current + 1)
      }
    }, 5000);
  }

  useEffect(() => {
    setTimeout(() => {
      autoPlay()
    }, 4000);
    return () => {
      clearInterval(timer.current)
    }
  }, [])

  useEffect(() => {
    currentIndex.current = selectIndex
  }, [selectIndex])

  const onMouseEnter = (index) => {
    clearInterval(timer.current)
    clearInterval(timer.current)
    setSelectIndex(index)
  }

  const onMouseLeave = (index) => {
    autoPlay()
  }

  const onSelect = (index) => {
    clearInterval(timer.current)
    setSelectIndex((pre) => pre === index ? -1 : index)
  }


  const list = [
    {
      style: styles.circleBig,
      delay: 0,
      img: Logo,
    },
    {
      style: styles.idols,
      label: t('wedo.game.title'),
      des: [
        t('wedo.game.content.part1'),
        t('wedo.game.content.part2'),
        t('wedo.game.content.part3'),
      ],
      img: GameIcon,
      delay: 500,
    },
    {
      style: styles.audiences,
      label: t('wedo.commerce.title'),
      des: [
        t('wedo.commerce.content.part1'),
        t('wedo.commerce.content.part2'),
      ],
      img: Logo,
      delay: 1500,
    },
    {
      style: styles.collectors,
      img: Logo,
      delay: 1000,
    },
    {
      style: styles.circleSmall,
      delay: 20,
      img: Logo,
    },
    {
      style: styles.circle4,
      icon: Circle4,
      delay: 500,
      img: Logo,
    },
  ]
  
  let selected = {}
  if (selectIndex > -1) {
    const validData = list.filter((item) => item.label)
    selected = validData[selectIndex] || {}
  }

  const textContainer = (
    <div className={styles.left}>
      <div className={styles.title}>{selected && selected.label ? selected.label : ''}</div>
      <div className={styles.line} />
      <div className={styles.content}>
        <ul>
          {
            (selected.des || []).map((item) => (
              <li key={item}>{item}</li>
            ))
          }
        </ul>
      </div>
    </div>
  )

  const circleContainer = (
    <div className={styles.center}>
      {
        list.map((item, index) => {
          if (!item.label) {
            return (
              <div
                key={`${index}`}
                className="wedo_ani"
                data-aos="zoom-in"
                data-aos-duration="1000"
                data-aos-once="true"
                data-aos-delay={item.delay}
              >
                <div
                  className={item.style}
                />
              </div>
            )
          }
          const position = index - 1
          return (
            <div
              key={`${index}`}
              className="wedo_ani"
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-once="true"
              data-aos-delay={item.delay}
            >
              <div
                className={classNames(item.style, styles.circle, selectIndex === position ? styles.circleSelect : null)}
                onMouseEnter={() => onMouseEnter(position)}
                onMouseLeave={() => onMouseLeave()}
                onClick={() => onSelect(position)}
              >
                {item.label}
              </div>
            </div>
          )
        })
      }
    </div>
  )

  const imgContainer = selected.img ? (
    <div className={styles.right}>
      <img className={styles.banner} src={selected.img} alt="cover" key={selected.label} />
    </div>
  ) : (
    <div className={styles.right}>
      <img className={styles.logo} src={Logo} alt="cover" key="logo" />
    </div>
  )

  return (
    <div className={styles.container}>
      {textContainer}
      {circleContainer}
      {imgContainer}
    </div>
  )
}

export default Wedo;
